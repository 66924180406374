<template>
  <a class="booking-now-mobile" :class="{
    'booking-now-mobile--offset': link
  }" :href="link" target="_blank">
    <span class="booking-now-mobile__text">{{ $t("bookingNow") }}</span>
  </a>
</template>

<script>
export default {
  name: 'BookingBtn',
  props: {
    link: {},
  },

};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helper/_variable.scss";

.booking-now-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .booking-now-mobile {
    display: inline-block;
    border-radius: 20px;
    height: 40px;
    padding: 0 35px;
    border: 2px solid $color-theme;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 20px));
    z-index: 100;
    background-color: $color-theme;

    &--offset {
      transform: translate(-50%, 0px);
    }

    &__text {
      display: inline-block;
      color: #fff;
      white-space: nowrap;
      line-height: 36px;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
  }
}
</style>
