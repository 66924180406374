<template>
  <div class="c-motto" v-if="visible">

    <div class="c-motto__content w1200" :class="{'c-motto__content--no-slogan': !doctor.slogan}">
      <div class="motto" v-if="doctor.slogan">
        <div class="quote quote--left">
          <img class="quote-icon" src="@/assets/img/doctorIntro/quotation_marks_1.svg" />
        </div>
        <div class="motto-sentence">
          <p class="motto-sentence__text">
            {{ doctor.slogan }}
          </p>
        </div>
        <div class="quote quote--right">
          <img class="quote-icon" src="@/assets/img/doctorIntro/quotation_marks_2.svg" />
        </div>
        <!-- <img class="moon" src="@/assets/img/doctorIntro/circle.svg" /> -->
        <div class="moon"></div>
      </div>

      <div class="doctor-name-social">
        <p class="name-title" v-if="doctor.name">
          <span class="name-title__name">{{ doctor.name }}</span>
          <span class="name-title__title">{{ doctor.title }}</span>
        </p>
        <div class="social-media">
          <a class="social-media__link" :href="doctor.fb" target="_blank" v-if="doctor.fb">
            <img class="social-media__link__icon" src="@/assets/img/doctorIntro/FB.svg" />
          </a>
          <a class="social-media__link" :href="doctor.ig" target="_blank" v-if="doctor.ig">
            <img class="social-media__link__icon" src="@/assets/img/doctorIntro/IG.svg" />
          </a>
          <a class="social-media__link" :href="doctor.weibo" target="_blank" v-if="doctor.weibo">
            <img class="social-media__link__icon" src="@/assets/img/doctorIntro/weibo.svg" />
          </a>
          <a class="social-media__link" :href="doctor.xiaohongshu" target="_blank" v-if="doctor.xiaohongshu">
            <img class="social-media__link__icon" src="@/assets/img/doctorIntro/xiaohongshu.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from 'lodash';

export default {
  name: 'Motto',
  props: {
    doctorData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    doctor() {
      return {
        slogan: get(this.doctorData, 'data.slogan', ''),
        name: get(this.doctorData, 'name', ''),
        title: get(this.doctorData, 'title', ''),
        fb: get(this.doctorData, 'data.social.fb', ''),
        ig: get(this.doctorData, 'data.social.ig', ''),
        weibo: get(this.doctorData, 'data.social.weibo', ''),
        xiaohongshu: get(this.doctorData, 'data.social.xiaohongshu', '')
      };
    },
    visible() {
      return this.doctor.slogan || this.doctor.fb || this.doctor.ig || this.doctor.weibo || this.doctor.xiaohongshu;
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
// * {
//   outline: 1px solid #f00;
// }
.c-motto {
  position: relative;

  &__content {
    padding: 200px 0;

    &--no-slogan {
      padding: 0 0 200px;
    }
  }
}

.motto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.quote {
  flex: none;
  width: 60px;

  &--left {
    position: relative;
    top: -15px;
  }

  &--right {
    position: relative;
    top: 15px;
  }

  .quote-icon {
    // width: 100%;
    width: 60px;
    vertical-align: bottom;

    .st2 {
      color: #ccc;
      fill: #ccc;
    }
  }
}

.motto-sentence {
  padding: 0 30px;
  &__text {
    font-size: 35px;
    letter-spacing: 1.5px;
    line-height: 40px;
    text-align: center;
  }
}

.moon {
  background-image: url("~@/assets/img/doctorIntro/circle.svg");
  // background-size: 442px;
  background-size: cover;
  background-repeat: no-repeat;
  // padding-top: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 442px;
  height: 442px;
}

.doctor-name-social {
  position: relative;
  top: 100px;
}

.name-title {
  flex: 1;
  text-align: center;

  &__name {
    font-size: 12px;
    letter-spacing: 1px;
  }

  &__title {
    margin-left: 14px;
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.social-media {
  display: flex;
  justify-content: center;

  &__link {
    margin-top: 16px;

    & + & {
      margin-left: 26px;
    }

    &__icon {
      width: 34px;
    }
  }
}

.deco-curve {
  width: 100vw;
  max-width: 100vw;
  // height: 70px;
  position: absolute;
  transform: translateY(calc(-100% + 1px));
  // top: -70px;

  &--mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .quote {
    .quote-icon {
      width: 40px;
    }
  }

  .moon {
    width: 300px;
    height: 300px;
  }
  .motto-sentence {
    box-sizing: border-box;
    padding: 0 10px;
    &__text {
      font-size: 20px;
      line-height: 28px;
    }
  }

}
</style>
