var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-doctor-cover"},[_c('div',{staticClass:"c-doctor-cover__content w1200"},[_c('div',{staticClass:"mobile-intro-header"},[_c('p',{staticClass:"mobile-intro-header__title"},[_c('span',{staticClass:"mobile-intro-header__title__name"},[_vm._v(_vm._s(_vm.doctorData.name))]),_c('span',{staticClass:"mobile-intro-header__title__position"},[_vm._v(_vm._s(_vm.doctorData.title))])])]),_c('div',{staticClass:"pc-intro-header"},[_c('div',{staticClass:"pc-intro-header__left"},[_c('span',{staticClass:"pc-intro-header__left__text"},[_vm._v(_vm._s(_vm.mainCategory))]),_c('img',{staticClass:"pc-intro-header__left__arrow-right",attrs:{"src":require("@/assets/img/doctorIntro/arrow-right.svg")}}),_c('router-link',{staticClass:"pc-intro-header__left__text",attrs:{"to":{
          name: 'Staff',
          params: {
            classification: 'procreations',
            typeId: _vm.slug
          }
        }}},[_vm._v(_vm._s(_vm.subCategory))])],1)]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"main__photo"},[_c('img',{staticClass:"main__photo__img",attrs:{"src":_vm.doctorData.avatar}})]),_c('div',{staticClass:"main__intro"},[_c('p',{staticClass:"intro__title"},[_c('span',{staticClass:"intro__title__name"},[_vm._v(_vm._s(_vm.doctorData.name))]),_c('span',{staticClass:"intro__title__position"},[_vm._v(_vm._s(_vm.doctorData.title))])]),(_vm.bookingUrl)?_c('div',{staticClass:"btn-wrap"},[_c('a',{attrs:{"href":_vm.bookingUrl,"target":"_blank"}},[_c('button',{staticClass:"btn p",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.$t("bookingNow"))+" ")])])]):_vm._e()])])]),(_vm.bookingUrl)?_c('BookingBtn',{attrs:{"link":_vm.bookingUrl}}):_vm._e(),(_vm.curverColor === 'white')?[_c('img',{staticClass:"deco-curve deco-curve--mobile",attrs:{"src":require("@/assets/img/doctorIntro/mob.svg")}}),_c('img',{staticClass:"deco-curve deco-curve--pc",attrs:{"src":require("@/assets/img/doctorIntro/web.svg")}})]:[_c('img',{staticClass:"deco-curve deco-curve--mobile",attrs:{"src":require("@/assets/img/doctorIntro/mob_grey.svg")}}),_c('img',{staticClass:"deco-curve deco-curve--pc",attrs:{"src":require("@/assets/img/doctorIntro/web_grey.svg")}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }