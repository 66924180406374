<template>
  <div class="c-story-share" v-if="stories.length">

    <div class="c-story-share__content w1200">
      <p class="title">{{$t('storyShare')}}</p>

      <div class="story-wrap">
        <ul class="story-list">
          <li class="story-list__item" v-for="(d, idx) of stories" :key="idx">
            <a class="story-list__item__box" :href="d.url" target="_blank">
              <div class="story-list__item__box__cover">
                <img class="story-list__item__box__cover__img" :src="`${domain}${d.cover}`" />
              </div>
              <span class="story-list__item__box__desc" >{{ d.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from 'lodash';

export default {
  name: 'StoryShare',
  props: {
    doctorData: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    stories() {
      return get(this.doctorData, 'data.storys', []).filter((item) => {
        const { url, cover, title } = item;
        const isEntireStory = url && cover && title;
        return isEntireStory;
      });
    },
    domain() {
      // if (process.env.NODE_ENV === 'development') {
      //   return 'https://nuwa-new-api.howdesign.com.tw';
      // }

      return process.env.VUE_APP_API_NEW;
    }
  }
};
</script>
<style lang="scss" scoped>
.c-story-share {
  box-sizing: border-box;
  padding: 100px 0;
  &__content {
  }
}

.title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 30px;
}
.story-wrap {
}

.story-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  &__item {
    width: 25%;

    @media screen and (max-width: 768px) {
      width: 50%;
      margin-top: 30px;

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 400px) {
      width: 100%;

      &:first-child,
      &:nth-child(2) {
        margin-top: 30px;
      }
    }

    &__box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;

      &__cover {
        width: 100%;

        &__img {
          width: 100%;
          border-radius: 8px;
        }
      }

      &__desc {
        margin-top: 32px;

        @media screen and (max-width: 768px) {
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
