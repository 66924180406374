<template>
  <div class="c-doctor-cover">
    <div class="c-doctor-cover__content w1200">
      <!-- mobile -->
      <div class="mobile-intro-header">
        <p class="mobile-intro-header__title">
          <span class="mobile-intro-header__title__name">{{ doctorData.name }}</span>
          <span class="mobile-intro-header__title__position">{{ doctorData.title }}</span>
        </p>
      </div>
      <!-- pc -->
      <div class="pc-intro-header">
        <div class="pc-intro-header__left">
          <span class="pc-intro-header__left__text">{{ mainCategory }}</span>
          <img class="pc-intro-header__left__arrow-right" src="@/assets/img/doctorIntro/arrow-right.svg" />
          <router-link class="pc-intro-header__left__text" :to="{
            name: 'Staff',
            params: {
              classification: 'procreations',
              typeId: slug
            }
          }">{{ subCategory }}</router-link>
        </div>
        <!-- <div class="pc-intro-header__center">{{ $t("ivfDoctorIntro") }}</div> -->
        <!-- <div class="pc-intro-header__right">
          <router-link
            :to="{
              name: 'Staff',
              params: {
                classification: 'procreations',
                typeId: 'p1'
              }
            }"
          >
            ABOUT DOCTORS
          </router-link>
        </div> -->
      </div>

      <div class="main">
        <div class="main__photo">
          <img class="main__photo__img" :src="doctorData.avatar" />
        </div>
        <div class="main__intro">
          <p class="intro__title">
            <span class="intro__title__name">{{ doctorData.name }}</span>
            <span class="intro__title__position">{{ doctorData.title }}</span>
          </p>

          <div class="btn-wrap" v-if="bookingUrl">
            <a :href="bookingUrl" target="_blank">
              <button type="button" class="btn p">
                {{ $t("bookingNow") }}
              </button>
            </a>

            <!-- <button type="button" class="gray-btn p" @click="toRegistrationChoose">
              {{ $t("clinicTable") }}
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <BookingBtn v-if="bookingUrl" :link="bookingUrl" />
    <!-- <button class="booking-now-mobile"
    :class="{
      'booking-now-mobile--offset': !visible
    }"
    @click="toRegistrationChoose">
      <span class="booking-now-mobile__text">{{ $t("bookingNow") }}</span>
    </button> -->

    <template v-if="curverColor === 'white'">
      <img class="deco-curve deco-curve--mobile" src="@/assets/img/doctorIntro/mob.svg" />
      <img class="deco-curve deco-curve--pc" src="@/assets/img/doctorIntro/web.svg" />
    </template>
    <template v-else>
      <img class="deco-curve deco-curve--mobile" src="@/assets/img/doctorIntro/mob_grey.svg" />
      <img class="deco-curve deco-curve--pc" src="@/assets/img/doctorIntro/web_grey.svg" />
    </template>

  </div>
</template>
<script>
import { get } from 'lodash';
import BookingBtn from './BookingBtn.vue';

export default {
  name: 'DoctorCover',
  props: {
    doctorData: {
      type: Object,
      default: () => ({})
    },
    teamsMapping: {
      type: Object,
      default: () => ({})
    },
    curverColor: {
      type: String,
      default: 'white'
    }
  },
  components: {
    BookingBtn,
  },
  computed: {
    doctor() {
      return {
        slogan: get(this.doctorData, 'data.slogan', ''),
        fb: get(this.doctorData, 'data.social.fb', ''),
        ig: get(this.doctorData, 'data.social.ig', ''),
        content: get(this.doctorData, 'content', '')
      };
    },
    visible() {
      return !!this.doctor.slogan || !!this.doctor.fb || !!this.doctor.ig;
    },
    slug() {
      return get(this.doctorData, 'team_type.slug', '');
    },
    bookingUrl() {
      return get(this.doctorData, 'url', '');
    },

    mainCategory() {
      if (this.teamsMapping[this.slug]) {
        return this.teamsMapping[this.slug].name;
      }

      return '';
    },
    subCategory() {
      if (this.teamsMapping[this.slug]) {
        return this.teamsMapping[this.slug].clinic;
      }

      return '';
    },
    showBookingBtn() {
      return this.doctor.slogan;
    }
  },
  methods: {
    toRegistrationChoose() {
      this.$router.push({
        name: 'RegistrationChoose',
        params: {
          classification: ''
        },
        query: {
          type: '3'
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/helper/_variable.scss";

.c-doctor-cover {
  box-sizing: border-box;
  padding-top: 100px;
  background-color: #e1e5e8;
  position: relative;

  &__content {}
}

.mobile-intro-header {
  display: none;
}

.pc-intro-header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  // margin-bottom: 36px;
  padding-bottom: 36px;
  position: relative;

  &__left {
    width: 260px;
    font-size: 14px;
    letter-spacing: 1.2px;
    // position: absolute;
    // top: 50%;
    // left: 0%;
    // transform: translateY(-50%);
    // white-space: nowrap;

    &__text {
      display: inline-block;
      vertical-align: bottom;
      font-size: 14px;
    }

    &__arrow-right {
      display: inline-block;
      vertical-align: bottom;
      width: 20px;
    }
  }

  &__center {
    flex: 1;
    font-size: 22px;
    text-align: center;
  }

  &__right {
    width: 260px;
    font-size: 14px;
    letter-spacing: 1.2px;
    text-align: right;
    // position: absolute;
    // top: 50%;
    // right: 0%;
    // transform: translateY(-50%);
    // white-space: nowrap;
  }
}

.main {
  display: flex;
  align-items: center;

  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  // padding-bottom: 50px;
  box-sizing: border-box;
  border-top: 1px solid rgba(#707070, 0.3);

  &__photo {
    flex: none;
    width: 50%;
    display: flex;
    box-sizing: border-box;
    padding: 0 50px;

    &__img {
      // width: 420px;
      width: 100%;
    }
  }

  &__intro {
    flex: none;
    width: 50%;
    box-sizing: border-box;
    transform: translateY(-20%);
    padding: 0 50px;
  }
}

.intro {
  &__title {
    &__name {
      font-size: 34px;
      letter-spacing: 2.6px;
      line-height: 35px;
    }

    &__position {
      margin-left: 27px;
      font-size: 18px;
      letter-spacing: 1.35px;
      line-height: 24px;
    }
  }
}

.intro-list-wrap {
  width: 100%;
  max-width: 420px;
  margin-top: 24px;
  margin-bottom: 43px;
}

.intro-list {
  &__item {
    position: relative;
    box-sizing: border-box;
    padding-left: 15px;

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      left: 5px;
      background-color: #000;
    }
  }
}

.btn-wrap {
  display: flex;
  margin-top: 60px;
}

.btn {
  &+& {
    margin-left: 10px;
  }
}

.gray-btn {
  border: 1px oslid #636569;
  background-color: #636569;
  border-radius: 24px;
  width: 195px;
  margin-left: 20px;
  color: #fff;
  // width: 100%;
}

.booking-now-mobile {
  display: none;
}

.deco-curve {
  width: 100vw;
  max-width: 100vw;
  // height: 70px;
  position: absolute;
  transform: translateY(calc(-100% + 1px));
  // top: -70px;

  &--mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .mobile-intro-header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    &__title {
      &__name {
        font-size: 28px;
        letter-spacing: 2.1px;
        line-height: 38px;
      }

      &__position {
        margin-left: 14px;
        font-size: 16px;
      }
    }
  }

  .pc-intro-header {
    display: none;
  }

  .main {
    justify-content: center;

    &__photo {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;

      // &__img {

      // }
    }

    &__intro {
      display: none;
    }
  }

  .deco-curve {
    width: 100%;

    &--mobile {
      display: none;
    }

    &--pc {
      display: block;
    }
  }

  .booking-now-mobile {
    display: inline-block;
    border-radius: 20px;
    height: 40px;
    padding: 0 35px;
    border: 2px solid $color-theme;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 20px));
    z-index: 100;
    background-color: $color-theme;

    &--offset {
      transform: translate(-50%, -20px);
    }

    &__text {
      color: #fff;
      white-space: nowrap;
    }
  }

  .deco-curve {
    &--mobile {
      display: block;
    }

    &--pc {
      display: none;
    }
  }
}
</style>
