<template>
  <div class="doctor-intro">
    <DoctorCover :doctorData="doctorData" :teamsMapping="teamsMapping" :curverColor="visible ? 'white' : 'grey'" />
    <Motto :doctorData="doctorData" />
    <Background :doctorData="doctorData" />
    <StoryShare :doctorData="doctorData" />
  </div>
</template>
<script>
import DoctorCover from '@/components/doctorIntro/DoctorCover.vue';
import Motto from '@/components/doctorIntro/Motto.vue';
import Background from '@/components/doctorIntro/Background.vue';
import StoryShare from '@/components/doctorIntro/StoryShare.vue';

import { getLangCode } from '@/utils/getLangCode';

import { getTeams } from '@/lib/cakeHttp';
import { mapState, mapMutations } from 'vuex';
import { get } from 'lodash';

// const API_URL = process.env.VUE_APP_API_NEW;

export default {
  name: 'DoctorIntro',
  components: {
    DoctorCover,
    Motto,
    Background,
    StoryShare
  },
  metaInfo() {
    return {
      meta: this.metaData
    };
  },
  data() {
    return {
      doctorData: {},
      teamsMapping: {},
      metaData: []
    };
  },
  computed: {
    ...mapState(['lang', 'cacheDoctorData', 'cacheTeamMapping']),
    doctorLen() {
      return Object.keys(this.cacheDoctorData || {});
    },
    doctor() {
      return {
        slogan: get(this.doctorData, 'data.slogan', ''),
        name: get(this.doctorData, 'name', ''),
        title: get(this.doctorData, 'title', ''),
        fb: get(this.doctorData, 'data.social.fb', ''),
        ig: get(this.doctorData, 'data.social.ig', ''),
        weibo: get(this.doctorData, 'data.social.weibo', ''),
        xiaohongshu: get(this.doctorData, 'data.social.xiaohongshu', '')
      };
    },
    visible() {
      return this.doctor.slogan || this.doctor.fb || this.doctor.ig;
    }
  },
  methods: {
    ...mapMutations(['setCacheDoctorData']),
    // genSeoData() {
    //   // 移除最後一個斜線
    //   const domain = API_URL.substr(0, API_URL.length - 1);
    //   const seoKeys = ['title', 'description', 'keyword', 'image'];

    //   const doctorMetas = this.doctorData.metas;

    //   if (!doctorMetas) return;

    //   const metas = seoKeys.reduce((list, key) => {
    //     if (key in doctorMetas) {
    //       const content = key === 'image' ? `${domain}${doctorMetas[key]}` : doctorMetas[key];

    //       const tempObj = {
    //         name: key,
    //         content
    //       };

    //       list.push(tempObj);
    //     }

    //     return list;
    //   }, []);

    //   const doctorOgs = this.doctorData.metas.og;
    //   const ogs = seoKeys.reduce((list, key) => {
    //     if (doctorOgs[key]) {
    //       const content = key === 'image' ? `${domain}${doctorOgs[key]}` : doctorOgs[key];

    //       const tempObj = {
    //         property: `og:${key}`,
    //         content
    //       };

    //       list.push(tempObj);
    //     }

    //     return list;
    //   }, []);

    //   this.metaData = [...metas, ...ogs];
    // },
    getDoctorData() {
      const doctorKey = `${this.$route.params.teamSlug}${this.$route.params.slug}${getLangCode()}`;
      // console.log('doctorKey', doctorKey);
      // console.log('cacheDoctorData', this.cacheDoctorData);

      this.doctorData = this.cacheDoctorData[doctorKey];
      this.teamsMapping = this.cacheTeamMapping;

      // this.genSeoData();
    },
    handleDoctorsData() {
      const isCache = this.doctorLen.length > 0;
      if (isCache) {
        this.getDoctorData();
        // return;
      }

      getTeams(
        this.lang,
        (data) => {
          this.setCacheDoctorData(data.data);

          this.getDoctorData();
        },

        (err) => {
          console.log(err);
        }
      );
    },
    checkDoctorExist() {
      if (!this.$route.params.teamSlug || !this.$route.params.slug) {
        this.$router.back();

        // this.$router.push({
        //   name: 'Staff',
        //   params: {
        //     classification: 'procreations',
        //     typeId: 'p1'
        //   }
        // });
      }
    }
  },
  created() {
    this.handleDoctorsData();
    this.checkDoctorExist();
  }
};
</script>
<style lang="scss" scoped>
.doctor-intro {
  width: 100%;
  overflow: hidden;
}
</style>
