<template>
  <div class="c-background">

    <img class="deco-img deco-img--right" src="@/assets/img/doctorIntro/bg_1.svg"/>
    <img class="deco-img deco-img--left" src="@/assets/img/doctorIntro/bg_2.svg"/>
    <div class="c-background__content w1200">
      <!-- <BookingBtn v-if="visible" :visible="visible"/> -->
      <div class="html-content" v-html="content" v-if="content"></div>
      <div class="col-wrap">
        <div class="col">

          <div class="title" v-if="youtubes.length > 0">
            <p class="title__text">{{$t('videoShare')}}</p>
          </div>

          <ul class="video-list" v-if="youtubes.length > 0">
            <li class="video-list__item" v-for="(d, idx) of youtubes" :key="d + String(idx)">
              <div class="video">
                <iframe
                  :src="d"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DoctorBackgroundSection from '@/components/doctorIntro/DoctorBackgroundSection.vue';
import { get } from 'lodash';
// import BookingBtn from './BookingBtn.vue';

export default {
  name: 'Background',
  // components: {
  //   DoctorBackgroundSection
  // },
  props: {
    doctorData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    // BookingBtn,
  },
  computed: {
    youtubes() {
      return get(this.doctorData, 'data.youtubes', [])
        .filter((url) => url)
        .map((url) => this.formatYTurl(url));
    },
    content() {
      return get(this.doctorData, 'content', '');
    },
    doctor() {
      return {
        slogan: get(this.doctorData, 'data.slogan', ''),
        fb: get(this.doctorData, 'data.social.fb', ''),
        ig: get(this.doctorData, 'data.social.ig', '')
      };
    },
    visible() {
      return !this.doctor.slogan && !this.doctor.fb && !this.doctor.ig;
    },
  },
  methods: {
    formatYTurl(url) {
      const prefix = [/^https:\/\/youtu.be\//, /^https:\/\/www.youtube.com\/watch\?v=/];
      const targetReg = prefix.find((reg) => reg.test(url));
      if (targetReg) return url.replace(targetReg, 'https://www.youtube.com/embed/');
      return url;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/helper/_variable.scss";

.c-background {
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding: 100px 0;
  position: relative;
  overflow: hidden;

  &__content {
    position: relative;
    z-index: 10;
  }

  .booking-now-mobile {
    position: relative;
    margin-bottom: 50px;

    &--offset {
      transform: translate(-50%, -20px);
    }
  }
}

.deco-img {
  position: absolute;
  // z-index: -1;

  &--right {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

  }

  &--left {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);

  }

}

.col-wrap {
  margin: 0 -18px;
  display: flex;
  flex-wrap: wrap;
}
.col {
  box-sizing: border-box;
  padding: 0 18px;
  width: 100%;

  &--50 {
    width: 50%;
  }
}

.html-content {
  margin-bottom: 100px;
}

.title {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(#707070, 0.3);
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: $color-theme;
  }

  &__text {
    font-size: 18px;
    letter-spacing: 1.35px;
    font-weight: 500;
    box-sizing: border-box;
    padding: 23px 0;
  }
}

.video-list {
  margin: 36px -18px 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    box-sizing: border-box;
    padding: 0 18px;
    width: 50%;

  }
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 40px;
  border-radius: 10px;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// @media screen and (max-width: 768px) {
//   .col {
//     &--50 {
//       width: 100%;
//     }
//   }
// }

@media screen and (max-width: 400px) {
  .col {
    &--50 {
      width: 100%;
    }
  }

  .video-list {
    &__item {
      width: 100%;
    }
  }

  .video {
    margin-bottom: 20px;
  }
}
</style>
